import React from "react"
import Layout from "../components/Layout"

const Internal = props => {
  const location = props.location
  const pageTitle = "Internal View"
  const pageSlug = "internal-view"

  return (
    <Layout location={location} pageSlug={pageSlug} pageTitle={pageTitle}>
      <div
        style={{
          padding: "6rem 0",
          textAlign: "center",
          display: "grid",
          placeItems: "center",
          height: "60vh",
        }}
      >
        <div className="container">
          <h1>{pageTitle}</h1>
        </div>
      </div>
    </Layout>
  )
}

export default Internal
